import useSWR from 'swr'
import { useMemo } from 'react'

import { useAuthContext } from '@/context/AuthContext'
import { useConfigsContext } from '@/context/ConfigsContext'
import { createShareComponentProfile } from '@/lib/auth'
import { getPointBalance } from '@/services/community'
import { HubsStoreState } from '@/types/store'

export const useShareComponentProfile = () => {
  const { store } = useConfigsContext()
  const { isSignedIn } = useAuthContext()

  const profile = store?.profile
  const htcToken = store?.credentials?.htcToken

  const { data } = useSWR(!htcToken ? null : 'pointBalance', () =>
    getPointBalance(htcToken!).then((res) => res?.results?.data),
  )

  const pointBalance = data?.pointBalance || 0
  const displayName = profile?.displayName
  const avatarId = profile?.avatarId
  const avatarSnapshot = profile?.avatarSnapshot
  const avatarGlb = profile?.avatarGlb
  const avatarGenderKey = profile?.avatarGenderKey
  const avatarDataType = profile?.avatarDataType
  const userId = profile?.userId

  const shareComponentProfile = useMemo(() => {
    const newProfile = {
      displayName,
      avatarId,
      avatarSnapshot,
      avatarGlb,
      avatarGenderKey,
      avatarDataType,
      userId,
    } as HubsStoreState['profile']
    return isSignedIn ? createShareComponentProfile(newProfile, pointBalance) : {}
  }, [
    isSignedIn,
    pointBalance,
    displayName,
    avatarId,
    avatarSnapshot,
    avatarGlb,
    avatarGenderKey,
    avatarDataType,
    userId,
  ])

  return shareComponentProfile
}
