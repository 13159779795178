import { cva } from 'class-variance-authority'

const bannerDescriptionVariants = cva('text-vive-white', {
  variants: {
    variant: {
      image: 'subheader-1 1.5xl:header-4 1.5xl:font-medium line-clamp-3 break-words',
      video: 'md:subheader-2 flex-1 body-1',
    },
  },
  defaultVariants: {
    variant: 'image',
  },
})

type BannerDescriptionProps = {
  description: string
  description_html?: string
  variant?: 'image' | 'video'
}

function BannerDescription({
  description,
  description_html,
  variant = 'image',
}: BannerDescriptionProps) {
  if (description_html) {
    return (
      <span
        className={bannerDescriptionVariants({ variant })}
        dangerouslySetInnerHTML={{ __html: description_html }}
      />
    )
  }

  return <span className={bannerDescriptionVariants({ variant })}>{description}</span>
}

export default BannerDescription
