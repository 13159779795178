'use client'

import { useRouter } from 'next/navigation'

import { CarouselItem } from '@/components/ui/carousel'
import { ActivityShowcaseList } from '@/services/cms'
import { cn } from '@/lib/utils'
import BannerVideo from '@/app/BannerVideo'
import BannerVideoInfo from '@/app/BannerVideoInfo'
import BannerImageInfo from '@/app/BannerImageInfo'
import BannerImage from '@/app/BannerImage'

type BannerItemProps = {
  item: ActivityShowcaseList
  index: number
  total: number
}

function BannerItem({ item, index, total }: BannerItemProps) {
  const router = useRouter()

  function handleActionLinkClick(actionLink: string) {
    const currentDomain = window.location.origin
    if (actionLink.startsWith(currentDomain)) {
      router.push(actionLink)
    } else {
      window.open(actionLink, '_blank')
    }
  }

  return (
    <CarouselItem
      key={item.title}
      className={cn(
        'relative basis-[calc(100dvw-109px)] overflow-hidden rounded-2xl bg-cover bg-center pl-0 md:basis-full',
        {
          'max-md:ml-3': index !== 0,
          'max-md:ml-6': index === 0,
          'max-md:mr-6': index === total - 1,
        },
      )}
    >
      {item.video ? (
        <BannerVideo item={item} index={index} />
      ) : (
        <BannerImage item={item} index={index} />
      )}

      {item.video ? (
        <BannerVideoInfo item={item} onClick={handleActionLinkClick} />
      ) : (
        <BannerImageInfo item={item} onClick={handleActionLinkClick} />
      )}
    </CarouselItem>
  )
}

export default BannerItem
