export const BI_SDK_LOADED_EVENT = 'bi_sdk_loaded'
export const BI_APP_ID = 'com.viverse.world'
export const BI_VERSION = '1.0'

// category
export const BI_CATEGORY_SHARE_WORLD = 'share_world'
export const BI_CATEGORY_SORT_AND_SEARCH = 'sort_and_search'
export const BI_CATEGORY_STARTER_WORLD = 'starter_world'
export const BI_CATEGORY_PAGE_VIEW = 'page_view'
export const BI_CATEGORY_SIGN_UP = 'signup'

// event
export const BI_EVENT_SHARE_FACEBOOK = 'share_facebook'
export const BI_EVENT_SHARE_TWITTER = 'share_twitter'
export const BI_EVENT_COPY_LINK = 'share_copylink'
export const BI_EVENT_MOST_VISITED = 'most_visited'
export const BI_EVENT_MOST_LIKED = 'most_liked'
export const BI_EVENT_LATEST = 'latest'
export const BI_EVENT_SEARCH_WORLD = 'search_world'
export const BI_EVENT_SEARCH_OWNER = 'search_owner'
export const BI_EVENT_CURATED_STARTER_WORLD = 'curated_starter_world'
export const BI_EVENT_WORLD_SIGN_UP = 'world_signup'

// page
export const BI_EVENT_OPEN_PAGE = 'open_page'
