import { cn } from '@/lib/utils'
import { cva } from 'class-variance-authority'

const bannerTitleVariants = cva('transition-all duration-1250', {
  variants: {
    variant: {
      image: 'header-2 line-clamp-2 break-words lg:text-[38px] 1.5xl:text-[64px] lg:font-bold',
      video: 'md:header-4 md:w-[140px] header-3 break-words flex',
      gallery: 'lg:subheader-1 body-2',
    },
  },
  defaultVariants: {
    variant: 'image',
  },
})

type BannerTitleProps = {
  title: string
  title_html?: string
  variant: 'image' | 'video' | 'gallery'
}

function BannerTitle({ title, title_html, variant }: BannerTitleProps) {
  if (variant === 'gallery') {
    if (title_html) {
      const text = title_html?.replace(/<[^>]+>|&[^;]+;/g, '')
      return <p className={cn(bannerTitleVariants({ variant }))}>{text}</p>
    } else {
      return <p className={cn(bannerTitleVariants({ variant }))}>{title}</p>
    }
  }

  if (title_html) {
    return (
      <p
        className={cn(bannerTitleVariants({ variant }))}
        dangerouslySetInnerHTML={{ __html: title_html }}
      />
    )
  }

  return <p className={cn(bannerTitleVariants({ variant }))}>{title}</p>
}

export default BannerTitle
