import { motion } from 'framer-motion'
import { ChevronDown } from 'lucide-react'
import { Button } from '@/components/ui/button'

type BannerScrollDownButtonProps = {
  showScrollDownButton: boolean
  onClick: () => void
}

function BannerScrollDownButton({ showScrollDownButton, onClick }: BannerScrollDownButtonProps) {
  return (
    <motion.div
      className="absolute -bottom-5 z-10 h-8 w-full"
      initial={{ opacity: 0 }}
      animate={{ opacity: showScrollDownButton ? 1 : 0 }}
      transition={{ duration: 0.5 }}
    >
      <Button
        onClick={onClick}
        className="z-10 size-full bg-vive-surface-normal hover:bg-vive-surface-highlighted"
      >
        <ChevronDown className="size-4" />
      </Button>
    </motion.div>
  )
}

export default BannerScrollDownButton
