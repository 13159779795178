'use client'

import { useTranslations } from 'next-intl'
import Link from 'next/link'

import HeroSection, { HeroSectionConfig } from '@/components/HeroSection'
import ViverseButton from '@/components/ViverseButton'
import IconDiscord from '@/components/assets/icon_discord.svg'
import communityMobileWebm from '@/public/videos/landing-heros/community@mobile.webm'
import communityMobileMp4 from '@/public/videos/landing-heros/community@mobile.mp4'
import communityPcWebm from '@/public/videos/landing-heros/community@pc.webm'
import communityPcMp4 from '@/public/videos/landing-heros/community@pc.mp4'

function CommunityHeroSection() {
  const t = useTranslations()

  const props: HeroSectionConfig = {
    banner: {
      title: t('landing-page.community-title'),
      description: t('landing-page.community-description'),
      videos: {
        mobile: {
          webm: communityMobileWebm,
          mp4: communityMobileMp4,
        },
        pc: {
          webm: communityPcWebm,
          mp4: communityPcMp4,
        },
      },
      action: (
        <Link target="_blank" href="https://discord.gg/viversecreators">
          <ViverseButton size="extra" variant="extra" className="gap-2">
            <IconDiscord className="size-5 lg:size-6" />
            {t('landing-page.community-cta')}
          </ViverseButton>
        </Link>
      ),
    },
  }

  return (
    <HeroSection
      className="mb-[90px] mt-[120px] lg:mb-0 1.5xl:mt-[200px]"
      variant="mask"
      {...props}
    />
  )
}

export default CommunityHeroSection
