import Image from 'next/image'
import { useEffect } from 'react'

import { ActivityShowcaseList } from '@/services/cms'
import { useHeroBannerContext } from '@/context/HeroBannerContext'

type BannerImageProps = {
  item: ActivityShowcaseList
  index: number
}

function BannerImage({ index, item }: BannerImageProps) {
  const { currentIndex, isPageVisible, setCurrentProgress, handleNext } = useHeroBannerContext()

  const autoDurationImage = !item.video && currentIndex === index && isPageVisible

  useEffect(() => {
    if (!autoDurationImage) return

    const timer = setTimeout(handleNext, 5000)
    let interval: NodeJS.Timeout

    //! 🥷🏼 Use this to set currentProgress to the image's duration
    setCurrentProgress(0)

    setTimeout(() => {
      interval = setInterval(() => {
        setCurrentProgress((prevProgress) => {
          if (prevProgress >= 100) {
            clearInterval(interval)
            return 100
          }
          return prevProgress + 2.5
        })
      }, 100)
    }, 50)

    return () => {
      clearTimeout(timer)
      clearInterval(interval)
    }
  }, [autoDurationImage, handleNext, setCurrentProgress])

  return <Image className="size-full object-cover" src={item.background!} alt={item.title} fill />
}

export default BannerImage
