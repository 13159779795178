import { useEffect, useState } from 'react'

export const useIsPageVisible = () => {
  const [isPageVisible, setIsPageVisible] = useState(true)

  useEffect(() => {
    const handleVisibilityChange = () => {
      const isPageVisible = document.visibilityState === 'visible'
      setIsPageVisible(isPageVisible)
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  return isPageVisible
}
