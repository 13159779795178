'use client'

import { memo, useCallback, useEffect, useRef, useState } from 'react'
import { useIsClient, useMediaQuery } from 'usehooks-ts'

import HeroBanner from '@/app/HeroBanner'
import BannerDots from '@/app/BannerDots'
import BannerGallery, { BannerGalleryRef } from '@/app/BannerGallery'
import { CarouselApi } from '@/components/ui/carousel'
import { ActivityShowcaseList } from '@/services/cms'
import { useIsPageVisible } from '@/hooks/useIsPageVisible'
import { HeroBannerProvider } from '@/context/HeroBannerContext'

type HeroBannerGalleryProps = {
  lists: ActivityShowcaseList[]
}

const HeroBannerGallery = memo(function HeroBannerGallery({ lists }: HeroBannerGalleryProps) {
  const [api, setApi] = useState<CarouselApi>()
  const [count, setCount] = useState(lists.length ?? 0)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [currentProgress, setCurrentProgress] = useState(0)

  const mainRef = useRef<HTMLDivElement>(null)
  const carouselRef = useRef<HTMLDivElement>(null)
  const galleryRef = useRef<BannerGalleryRef>(null)

  const matchesMd = useMediaQuery('(min-width: 768px)')
  const isClient = useIsClient()
  const isPageVisible = useIsPageVisible()

  useEffect(() => {
    if (!api) return

    setCount(api.scrollSnapList().length)
    setCurrentIndex(api.selectedScrollSnap())

    api.on('select', () => {
      const index = api.selectedScrollSnap()
      setCurrentIndex(index)
      galleryRef.current?.scrollToItem(index)
    })
  }, [api, currentIndex, lists])

  const handleGoTo = useCallback(
    (index: number) => {
      if (!api) return
      api.scrollTo(index, !matchesMd)
    },
    [api, matchesMd],
  )

  const handleNext = useCallback(() => {
    const nextIndex = currentIndex + 1
    const index = nextIndex >= count ? 0 : nextIndex
    handleGoTo(index)
  }, [count, currentIndex, handleGoTo])

  return (
    <HeroBannerProvider
      lists={lists}
      isPageVisible={isPageVisible}
      currentIndex={currentIndex}
      currentProgress={currentProgress}
      setCurrentProgress={setCurrentProgress}
      handleNext={handleNext}
      handleGoTo={handleGoTo}
    >
      <main
        ref={mainRef}
        className="relative mt-[52px] flex w-dvw bg-background py-9 md:mt-[56px] md:px-[34px] md:py-8 lg:mt-[80px] lg:px-[64px] lg:py-8 3xl:px-[80px] 3xl:py-12"
      >
        <section className="relative flex h-auto w-full flex-1 items-center justify-center md:h-auto md:flex-col md:justify-start md:gap-4 lg:flex-row lg:justify-center lg:gap-6 1.5xl:mb-[46px] 3xl:mb-[22px] 3xl:gap-4 3xl:px-[177px]">
          <HeroBanner ref={carouselRef} setApi={setApi} />
          {/** 🥷🏼 use isClient to prevent hydration error */}
          {isClient && matchesMd && (
            <BannerGallery ref={galleryRef} carouselRef={carouselRef} mainRef={mainRef} />
          )}
          <BannerDots count={count} />
        </section>
      </main>
    </HeroBannerProvider>
  )
})

export default HeroBannerGallery
