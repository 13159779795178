import IconSpinner from '@/components/assets/icon_spinner.svg'
import { cn } from '@/lib/utils'

function Loader({ className = '' }) {
  return (
    <div className={cn('size-6 animate-spinner-rotate', className)}>
      <IconSpinner />
    </div>
  )
}

export default Loader
