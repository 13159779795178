import {
  forwardRef,
  MutableRefObject,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useMediaQuery, useResizeObserver } from 'usehooks-ts'

import { ActivityShowcaseList } from '@/services/cms'
import BannerGalleryItem from '@/app/BannerGalleryItem'
import BannerScrollDownButton from '@/app/BannerScrollDownButton'
import { useHeroBannerContext } from '@/context/HeroBannerContext'

type BannerGalleryProps = {
  mainRef: MutableRefObject<HTMLDivElement | null>
  carouselRef: MutableRefObject<HTMLDivElement | null>
}

export type BannerGalleryRef = {
  scrollToItem: (index: number) => void
}

const BannerGallery = forwardRef<BannerGalleryRef, BannerGalleryProps>(function BannerGallery(
  { mainRef, carouselRef },
  ref,
) {
  const { lists, currentIndex, handleGoTo } = useHeroBannerContext()
  const [showScrollDownButton, setShowScrollDownButton] = useState(false)

  const galleryRef = useRef<HTMLDivElement>(null)
  const galleryItemRefs = useRef<HTMLDivElement[]>([])

  const matchesLg = useMediaQuery('(min-width: 1024px)')

  const { width: mainWidth } = useResizeObserver({
    ref: mainRef,
  })

  const { height: carouselHeight } = useResizeObserver({
    ref: carouselRef,
  })

  const scrollDown = () => {
    if (!galleryRef.current) return
    galleryRef.current.scrollTo({
      top: galleryRef.current.scrollHeight,
      behavior: 'smooth',
    })
  }

  const scrollToItem = useCallback(
    (index: number) => {
      const gallery = galleryRef.current
      const item = galleryItemRefs.current[index]

      if (!gallery || !item) return

      if (matchesLg) {
        gallery.scrollTo({
          top: item.offsetTop - 48,
          behavior: 'smooth',
        })
      } else {
        gallery.scrollTo({
          left: item.offsetLeft - 48,
          behavior: 'smooth',
        })
      }
    },
    [matchesLg],
  )

  const handleScroll = useCallback(() => {
    const { scrollHeight, clientHeight, scrollTop } = galleryRef.current!
    const isBottom = scrollHeight - clientHeight === scrollTop

    // 🥷🏼 force 1440 and item 4 not showing
    if (lists.length <= 4 && innerWidth === 1440) return

    setShowScrollDownButton(!isBottom)
  }, [lists])

  useImperativeHandle(
    ref,
    () => ({
      scrollToItem,
    }),
    [scrollToItem],
  )

  useEffect(() => {
    if (!galleryRef.current) return

    const gallery = galleryRef.current

    handleScroll()

    gallery.addEventListener('scroll', handleScroll)

    return () => {
      gallery.removeEventListener('scroll', handleScroll)
    }
    // set mainWidth as a dependency to recalculate
  }, [mainWidth, handleScroll])

  return (
    <div className="relative flex w-full lg:h-full lg:w-[248px] lg:min-w-[248px] lg:px-0">
      <div
        ref={galleryRef}
        className="no-scrollbar flex w-full flex-row gap-4 overflow-hidden md:items-center md:overflow-x-auto lg:flex-col lg:overflow-y-auto"
        style={{
          height: matchesLg ? carouselHeight : 86,
        }}
      >
        {lists.map((list, index) => (
          <BannerGalleryItem
            ref={(ref) => {
              galleryItemRefs.current[index] = ref!
            }}
            key={index}
            list={list}
            isActive={currentIndex === index}
            onClick={() => handleGoTo(index)}
          />
        ))}
      </div>
      {matchesLg && (
        <BannerScrollDownButton showScrollDownButton={showScrollDownButton} onClick={scrollDown} />
      )}
    </div>
  )
})

export default BannerGallery
