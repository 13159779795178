import { applyUnderline } from '@/lib/utils'
import { getUserLocale } from '@/server/services/locales'
import { API_ENPOINT_LOGIN, API_ENPOINT_LOGOUT } from '@/services/api'
import { getHubsDomain, getViveAvatarDomain, getVodafoneURLPostfix } from '@/configs/utils'

export function getLocationHrefUrl() {
  const hubsDomain = getHubsDomain()
  const whiteList = [hubsDomain, 'localhost']
  const mainPage = `https://${hubsDomain}`

  // TODO need to handle errors page
  if (
    window.location.pathname.includes('/errors') ||
    !whiteList.includes(window.location.hostname)
  ) {
    return mainPage
  } else {
    return window.location.href
  }
}

function getReferrerUrl() {
  const hubsDomain = getHubsDomain()
  const mainPage = `https://${hubsDomain}`

  if (!document.referrer) {
    // TODO need to handle errors page
    if (window.location.pathname.includes('/errors') || window.location.hostname !== hubsDomain) {
      return mainPage
    } else {
      return window.location
    }
  }

  const url = new URL(document.referrer)
  // TODO need to handle errors page
  if (url.pathname.includes('/errors') || url.hostname !== hubsDomain) {
    return mainPage
  } else {
    return url.origin + url.pathname + url.search
  }
}

export async function getLoginUrl() {
  const locale = applyUnderline(await getUserLocale())
  const hubsDomain = getHubsDomain()
  const loginUrl = `https://${hubsDomain}${API_ENPOINT_LOGIN}?hl=${locale}`
  const vodafoneURLPostfix = getVodafoneURLPostfix()

  if (vodafoneURLPostfix) {
    const postfixArray = vodafoneURLPostfix.split(',')
    const pathname = window.location.pathname
    const isVodafoneEvent = postfixArray.some((postfix) => pathname.includes('/' + postfix))

    if (isVodafoneEvent) {
      return `${loginUrl}&relevance=vodafone&callback=${getLocationHrefUrl()}`
    }
  }

  return `${loginUrl}&callback=${getLocationHrefUrl()}`
}

export async function getLogoutUrl() {
  const locale = applyUnderline(await getUserLocale())
  const hubsDomain = getHubsDomain()
  return `https://${hubsDomain}${API_ENPOINT_LOGOUT}?hl=${locale}&callback=${getReferrerUrl()}#logout`
}

export function getViveCreatorUrl() {
  const viveAvatarDomain = getViveAvatarDomain()
  return `https://${viveAvatarDomain}/avatar/creator?redirect_url=${encodeURIComponent(
    getLocationHrefUrl(),
  )}`
}
