import { useEffect, useRef, useState } from 'react'

import { useHeroBannerContext } from '@/context/HeroBannerContext'
import { ActivityShowcaseList } from '@/services/cms'

type BannerVideoProps = {
  index: number
  item: ActivityShowcaseList
}

function BannerVideo({ index, item }: BannerVideoProps) {
  const videoRef = useRef<HTMLVideoElement>(null)
  const [videoDuration, setVideoDuration] = useState(0)

  const { currentIndex, isPageVisible, setCurrentProgress, handleNext } = useHeroBannerContext()

  useEffect(() => {
    if (videoRef.current) {
      // 🥷🏼 force video load to get meta correctly
      videoRef.current.load()
    }
  }, [])

  useEffect(() => {
    const video = videoRef.current

    if (video && isPageVisible) {
      video.currentTime = 0

      if (currentIndex === index) {
        video.play()
      } else {
        videoRef.current?.pause()
      }
    }
  }, [currentIndex, index, isPageVisible, setCurrentProgress])

  function handleVideoEnd() {
    if (currentIndex !== index) return
    handleNext()
  }

  function handleLoadedMetadata() {
    setVideoDuration(videoRef.current?.duration || 0)
  }

  function handleTimeUpdate() {
    const video = videoRef.current
    if (!video || !videoDuration) return

    const progress = Number(((video.currentTime / videoDuration) * 100).toFixed(2))
    setCurrentProgress(progress)
  }

  return (
    <video
      ref={videoRef}
      className="absolute inset-0 size-full object-cover"
      muted
      playsInline
      onEnded={handleVideoEnd}
      onTimeUpdate={handleTimeUpdate}
      onLoadedMetadata={handleLoadedMetadata}
    >
      {item.video!.webm && <source src={item.video!.webm} type="video/webm" />}
      {item.video!.mp4 && <source src={item.video!.mp4} type="video/mp4" />}
    </video>
  )
}

export default BannerVideo
