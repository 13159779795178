import { useTranslations } from 'next-intl'
import BannerDescription from '@/app/BannerDescription'
import BannerTitle from '@/app/BannerTitle'
import ViverseButton from '@/components/ViverseButton'
import { ActivityShowcaseList } from '@/services/cms'

type BannerVideoInfoProps = {
  item: ActivityShowcaseList
  onClick: (link: string) => void
}

function BannerVideoInfo({ item, onClick }: BannerVideoInfoProps) {
  const t = useTranslations()
  return (
    <div className="absolute bottom-0 flex w-full items-center justify-center md:bottom-6 md:px-6">
      <div className="flex flex-1 flex-col items-center gap-4 rounded-2xl bg-hero-card-description-mobile p-4 md:flex-row md:bg-[#212A3933] md:bg-none md:backdrop-blur-[100px]">
        <div className="flex flex-1 flex-col items-start gap-4 md:flex-row md:items-center">
          <BannerTitle title={item.title} title_html={item.title_html} variant="video" />
          <BannerDescription
            description={item.description}
            description_html={item.description_html}
            variant="video"
          />
        </div>
        {item.action_link && (
          <div className="flex w-full flex-col items-center justify-center gap-[10px] md:w-auto">
            <ViverseButton
              size="filter"
              variant="default"
              className="h-[34px] w-full md:w-fit md:!min-w-fit"
              onClick={() => onClick(item.action_link)}
            >
              {item.action_title}
            </ViverseButton>
            {item.enable_no_signup_hint && (
              <div className="hidden text-xs text-white md:block">
                {t('homepage.banner.cta_tips')}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default BannerVideoInfo
