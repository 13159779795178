import { HTMLAttributes } from 'react'
import { cn } from '@/lib/utils'
import Link from 'next/link'

interface StackCardsContainerProps extends HTMLAttributes<HTMLDivElement> {
  title?: string
  titleLink?: string
  subtitle?: string
  titleClassName?: string
  childrenClassName?: string
}

function StackCardsContainer({
  title,
  subtitle,
  children,
  titleClassName,
  childrenClassName,
  titleLink,
  ...props
}: StackCardsContainerProps) {
  return (
    <div {...props}>
      {title && (
        <h2
          className={cn(
            'subheader-1 1.5xl:header-4 relative mx-auto mb-6 w-full max-w-screen-4xl px-6 lg:mb-9 lg:px-[70px]',
            titleClassName,
          )}
        >
          {title}
          {titleLink && (
            <Link href={titleLink} className="absolute left-0 top-0 size-full cursor-pointer" />
          )}
        </h2>
      )}
      {subtitle && (
        <h3 className="subheader-2 lg:header-4 mx-auto mb-3 w-full max-w-screen-4xl px-6 !text-vive-primary-20 lg:px-[70px]">
          {subtitle}
        </h3>
      )}

      <div className="no-scrollbar w-full overflow-scroll">
        <div
          className={cn(
            'mx-auto flex w-fit min-w-full max-w-screen-4xl gap-3 px-6 lg:grid lg:w-full lg:shrink-0 lg:auto-rows-max lg:grid-cols-3 lg:gap-5 lg:px-[70px] xl_2:grid-cols-4 2xl_2:grid-cols-5 4xl:min-w-0',
            childrenClassName,
          )}
        >
          {children}
        </div>
      </div>
    </div>
  )
}

export default StackCardsContainer
