import { ComponentProps } from 'react'
import { CircleX } from 'lucide-react'
import { Button } from '@/components/ui/button'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { cn } from '@/lib/utils'

type Option = {
  label: string
  value: string
}

type ViverseAutoCompleteProps = ComponentProps<typeof Select> & {
  placeholder: string
  selected: Option[]
  options: Option[]
  onDeleteClick: (value: string) => void
}

export default function ViverseAutoComplete({
  placeholder,
  selected = [],
  options = [],
  onDeleteClick,
  ...props
}: ViverseAutoCompleteProps) {
  return (
    <div className="flex flex-col">
      <Select {...props}>
        <SelectTrigger className="h-14 min-w-max border-2 border-vive-primary-70 bg-vive-popup text-vive-primary-40 transition-all hover:border-vive-brand-50">
          <SelectValue asChild placeholder={placeholder}>
            <div className="text-vive-primary-40">{placeholder}</div>
          </SelectValue>
        </SelectTrigger>
        <SelectContent className="max-w-[calc(100%-4px)] border-2 border-vive-primary-70 bg-vive-popup">
          {options.map((option) => (
            <SelectItem
              key={option.value}
              value={option.value}
              className="max-w-[calc(100%-4px)] cursor-pointer focus:bg-transparent focus:hover:bg-vive-primary-70"
              withChecked={false}
            >
              {option.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      <div
        className={cn('flex flex-wrap gap-2', {
          'mt-3': selected.length > 0,
        })}
      >
        {selected.map((option) => (
          <Button
            className="gap-2 rounded-full bg-vive-primary-70 text-vive-primary-20 hover:text-vive-primary-10"
            key={option.value}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
            }}
          >
            {option.label}
            <CircleX
              onClick={() => onDeleteClick?.(option.value)}
              fill="#D5E2F2"
              stroke="#364356"
            />
          </Button>
        ))}
      </div>
    </div>
  )
}
