'use client'

import { ComponentProps, PropsWithChildren, ReactNode, MouseEvent, useState } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { useTranslations } from 'next-intl'
import { CardDescription, CardTitle } from '@/components/ui/card'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import ViverseCard from '@/components/ViverseCard'
import { Skeleton } from '@/components/ui/skeleton'
import PopoverMenu from '@/components/PopoverMenu'
import ConfirmDialog from '@/components/ConfirmDialog'
import WorldSettings, { WorldSettingsDefaultValues } from '@/components/WorldSettings'
import IconMoreAction from '@/components/assets/icon_more_action.svg'
import IconWatch from '@/components/assets/icon_watch.svg'
import IconLike from '@/components/assets/icon_like.svg'
import IconFB from '@/components/assets/icon_fb.svg'
import IconTwitter from '@/components/assets/icon_twitter.svg'
import IconLink from '@/components/assets/icon_link.svg'
import IconLikeBordered from '@/components/assets/icon_like_bordered.svg'
import IconLikeFilled from '@/components/assets/icon_like_filled.svg'
import { useShareLink } from '@/hooks/useShareLink'
import { useRoomPolicy } from '@/hooks/useRoomPolicy'
import { useLikedRoom } from '@/hooks/useLikedRoom'
import {
  CreateAGiveawayRoomData,
  deleteRoom,
  patchRoom,
  Room,
  withdrawCoOwnedRoom,
} from '@/services/cms'
import { numberFormatter } from '@/lib/formatter'
import { useConfigsContext } from '@/context/ConfigsContext'
import { Policy } from '@/types/world'
import { cn } from '@/lib/utils'
import CreateWorldDialog from '@/components/CreateWorldDialog'
import roomDefaultImage from '@/public/images/room-default.png'

type CountViewProps = {
  icon: ReactNode
  count: number
}

function CountView({ count, icon }: CountViewProps) {
  return (
    <div className="flex items-center justify-between">
      <span className="flex size-3">{icon}</span>
      <span className="ml-[6px] flex">{numberFormatter(count)}</span>
    </div>
  )
}

type MoreActionProps = PropsWithChildren & {
  info: Partial<Room>
  shareOnly?: boolean
  isCoOwnedRoom?: boolean
}

function MoreAction({ info, shareOnly = false, isCoOwnedRoom = false, children }: MoreActionProps) {
  const t = useTranslations()
  const { store } = useConfigsContext()
  const htcToken = store?.credentials?.htcToken
  const [open, setOpen] = useState(false)
  const { copyLink, setLinkIsCopied, linkIsCopied, shareToPlatform } = useShareLink(info.hubs_url)
  const profileId = store?.profile?.id

  const ROOM_POLICY = useRoomPolicy()

  const handlePopoverOpenChange = async () => {
    setOpen((open) => {
      if (open) {
        setLinkIsCopied(false)
      }
      return !open
    })
  }

  const handleClickIcon = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    e.nativeEvent.preventDefault()
  }
  const handleDeleteRoom = async () => {
    if (!htcToken) throw new Error('missing htcToken')
    if (!info.id) throw new Error('missing room id')
    const res = await deleteRoom(htcToken, info.id)
    if (res?.status === 200) {
      await setOpen(false)
      await window.location.reload()
    }
  }

  const handleWithDrawCoOwnedRoom = async () => {
    if (!htcToken) throw new Error('missing htcToken')
    if (!info.id) throw new Error('missing room id')
    if (!profileId) throw new Error('missing profile id')
    const res = await withdrawCoOwnedRoom(htcToken, info.id, profileId)
    if (res?.status === 200) {
      await setOpen(false)
      await window.location.reload()
    }
  }

  const handleWorldSettingsComplete = async (data: CreateAGiveawayRoomData) => {
    if (!htcToken) throw new Error('missing htcToken')
    if (!info?.id) throw new Error('missing room id')
    const res = await patchRoom(htcToken, info.id, data)
    if (res?.status === 200) {
      await window.location.reload()
    }
  }

  const matchedPolicy = Object.entries(ROOM_POLICY).find(
    ([key, { value }]) => value === info.policy,
  )
  const worldDefaultValues: WorldSettingsDefaultValues = {
    title: info.title || '',
    description: info.description_plaintext || '',
    policy: (matchedPolicy ? matchedPolicy[0] : 'private') as Policy,
    passcode: '',
    genre: info.tags,
    enablePasscode: !!info.is_protected,
  }

  const worldDefaultThumbnail = {
    id: '',
    url: info.image || roomDefaultImage.src,
  }

  return (
    <Popover open={open} onOpenChange={handlePopoverOpenChange}>
      <PopoverTrigger asChild onClick={handleClickIcon}>
        <div className="subheader-2 1.5xl:subheader-1 size-5 1.5xl:ml-3 1.5xl:size-6 [&_path]:hover:fill-primary">
          <IconMoreAction />
        </div>
      </PopoverTrigger>
      <PopoverContent align="start" className="border-none bg-transparent p-0">
        {children ||
          (info.hubs_url && (
            <PopoverMenu>
              <PopoverMenu.Group title={t('profile-page.share')}>
                <PopoverMenu.Item onClick={() => shareToPlatform('facebook')}>
                  <IconFB className="size-6 fill-[#D5E2F2]" />
                  <div className="body-1 text-inherit">{t('profile-page.share-facebook')}</div>
                </PopoverMenu.Item>
                <PopoverMenu.Item onClick={() => shareToPlatform('twitter')}>
                  <IconTwitter className="size-6 fill-[#D5E2F2]" />
                  <div className="body-1 text-inherit">{t('profile-page.share-twitter')}</div>
                </PopoverMenu.Item>
                <PopoverMenu.Item onClick={copyLink}>
                  <IconLink className="size-6" />
                  <div className="body-1 text-inherit">
                    {linkIsCopied
                      ? t('profile-page.share-link-copied')
                      : t('profile-page.share-copy-link')}
                  </div>
                </PopoverMenu.Item>
              </PopoverMenu.Group>
              {!shareOnly && (
                <>
                  <PopoverMenu.Seperator />
                  <PopoverMenu.Item>
                    <CreateWorldDialog
                      dialogTrigger={
                        <div className="body-1 w-full text-left text-inherit">
                          {t('profile-page.my-worlds-card-settings')}
                        </div>
                      }
                      title={t('profile-page.my-worlds-card-settings')}
                    >
                      <WorldSettings
                        defaultValues={worldDefaultValues}
                        defaultThumbnail={worldDefaultThumbnail}
                        onComplete={handleWorldSettingsComplete}
                        mode="update"
                      />
                    </CreateWorldDialog>
                  </PopoverMenu.Item>
                  {isCoOwnedRoom ? (
                    <PopoverMenu.Item>
                      <ConfirmDialog
                        dialogTrigger={
                          <div className="body-1 w-full text-left text-inherit">
                            {t('profile-page.co-owned-room-withdraw-cta')}
                          </div>
                        }
                        title={t('profile-page.co-owned-room-withdraw-cta')}
                        content={t('profile-page.co-owned-room-withdraw-caption')}
                        onConfirm={handleWithDrawCoOwnedRoom}
                      />
                    </PopoverMenu.Item>
                  ) : (
                    <PopoverMenu.Item>
                      <ConfirmDialog
                        dialogTrigger={
                          <div className="body-1 w-full text-left text-inherit">
                            {t('profile-page.my-worlds-card-delete')}
                          </div>
                        }
                        title={t('profile-page.my-worlds-card-delete')}
                        content={t('profile-page.my-worlds-card-delete-caption')}
                        onConfirm={handleDeleteRoom}
                      />
                    </PopoverMenu.Item>
                  )}
                </>
              )}
            </PopoverMenu>
          ))}
      </PopoverContent>
    </Popover>
  )
}

type ActionWorldCardProps = PropsWithChildren<ComponentProps<typeof ViverseCard>> & {
  info: Partial<Room>
  href?: string
  showMoreAction?: boolean
  moreActionContent?: ReactNode
  isLive?: boolean
  liveCount?: number
  customDesc?: string
  hideLiked?: boolean
  shareOnly?: boolean
  className?: string
  isCoOwnedRoom?: boolean
}

function ActionWorldCard({
  href,
  info,
  showMoreAction = false,
  moreActionContent,
  customDesc,
  hideLiked = false,
  shareOnly = false,
  isCoOwnedRoom = false,
  className,
  ...props
}: ActionWorldCardProps) {
  const {
    id,
    title,
    image = roomDefaultImage.src,
    view_count,
    like_count,
    hubs_url,
    owner,
    is_liked,
    is_live,
  } = info
  const { isLiked, handleClickLike } = useLikedRoom(id || '', href || hubs_url || '/', is_liked)

  return (
    <ViverseCard {...props} className={className}>
      <ViverseCard.Content className="group/card mb-[6px] flex aspect-[1.72] w-full items-center align-middle lg:mb-2">
        {is_live && (
          <div className="absolute left-3 top-3 z-10">
            <Link href={href || hubs_url || '/'} className="cursor-pointer">
              <div className="caption flex items-center gap-2 rounded-full bg-[#00000099] px-2 text-white">
                <span className="size-2 rounded-full bg-vive-live" />
                Live
              </div>
            </Link>
          </div>
        )}

        {!hideLiked && (
          <div
            className="absolute right-3 top-[10px] z-10 size-6 opacity-0 transition-opacity duration-150 group-hover/card:opacity-100 1.5xl:size-7"
            onClick={handleClickLike}
          >
            {isLiked ? <IconLikeFilled /> : <IconLikeBordered />}
          </div>
        )}

        <Link href={href || hubs_url || '/'} className="block size-full cursor-pointer">
          <Image
            src={image || roomDefaultImage.src}
            className="transition-transform duration-300 hover:scale-110"
            fill
            alt="go-to-world"
            style={{ objectFit: 'cover' }}
          />
        </Link>
      </ViverseCard.Content>

      <ViverseCard.Header className="mt-0">
        <div className="flex items-center justify-between">
          <Link
            href={href || hubs_url || '/'}
            className={cn(
              'cursor-pointer text-left',
              { 'w-full': !showMoreAction },
              { 'w-[calc(100%-20px)] 1.5xl:w-[calc(100%-36px)]': showMoreAction },
            )}
          >
            <CardTitle className="subheader-2 1.5xl:subheader-1 truncate 1.5xl:h-[32px]">
              {title}
            </CardTitle>
          </Link>
          {showMoreAction && id && (
            <MoreAction info={info} shareOnly={shareOnly} isCoOwnedRoom={isCoOwnedRoom}>
              {moreActionContent}
            </MoreAction>
          )}
        </div>

        <div className="mt-0 flex justify-between">
          <Link
            href={`/profile/${owner?.htc_account_id}`}
            className="block max-w-[calc(100%-98px)] cursor-pointer lg:max-w-[calc(100%-106px)]"
          >
            <CardDescription className="truncate text-left 1.5xl:text-[14px] 1.5xl:tracking-[0.22px] 1.5xl:text-vive-primary-30">
              {customDesc ? customDesc : owner?.display_name}
            </CardDescription>
          </Link>

          <div className="1.5xl:caption flex gap-4 text-[10px] text-vive-primary-20 1.5xl:ml-3">
            <CountView icon={<IconWatch />} count={view_count ?? 0} />
            <CountView icon={<IconLike />} count={like_count ?? 0} />
          </div>
        </div>
      </ViverseCard.Header>
    </ViverseCard>
  )
}

export function ActionWorldCardSkeleton() {
  return <Skeleton className="h-[204px] w-[248px] 1.5xl:h-[246px] 1.5xl:w-[310px]" />
}

ActionWorldCard.Skeleton = ActionWorldCardSkeleton

export default ActionWorldCard
