'use client'

import { forwardRef } from 'react'
import Fade from 'embla-carousel-fade'
import { useMediaQuery } from 'usehooks-ts'

import { Carousel, CarouselApi, CarouselContent } from '@/components/ui/carousel'
import { useHeroBannerContext } from '@/context/HeroBannerContext'
import BannerItem from '@/app/BannerItem'

type HeroBannerProps = {
  setApi: (api: CarouselApi) => void
}

const HeroBanner = forwardRef<HTMLDivElement, HeroBannerProps>(function HeroBanner(
  { setApi },
  ref,
) {
  const matchesMd = useMediaQuery('(min-width: 768px)')
  const { lists } = useHeroBannerContext()
  return (
    <Carousel
      ref={ref}
      className="group relative aspect-[390/500] size-full md:aspect-[700/400] md:h-auto 1.5xl:aspect-[1040/560] 3xl:aspect-[1142/576] [&>div]:h-full [&>div]:rounded-2xl"
      opts={{
        align: 'center',
        loop: matchesMd,
        duration: 60,
      }}
      setApi={setApi}
      plugins={[
        Fade({
          active: matchesMd,
        }),
      ]}
    >
      <CarouselContent className="ml-0 h-full">
        {lists.map((item, index, array) => (
          <BannerItem key={item.title} total={array.length} item={item} index={index} />
        ))}
      </CarouselContent>
    </Carousel>
  )
})

export default HeroBanner
