'use client'

import { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react'
import Loader from '@/components/Loader'

type AppLoaderContextType = {
  setIsAppLoading: (isLoading: boolean) => void
}

const AppLoaderContext = createContext<AppLoaderContextType | null>(null)

export function AppLoaderProvider({ children }: PropsWithChildren) {
  const [isAppLoading, setIsAppLoading] = useState(false)

  useEffect(() => {
    if (isAppLoading) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
    }
  }, [isAppLoading])

  return (
    <AppLoaderContext.Provider value={{ setIsAppLoading }}>
      {isAppLoading && (
        <div
          className="fixed inset-0 z-50 grid h-dvh w-dvw cursor-wait place-items-center"
          style={{
            background: 'rgba(0, 0, 0, 0.4)',
          }}
        >
          <Loader />
        </div>
      )}
      {children}
    </AppLoaderContext.Provider>
  )
}

export const useAppLoaderContext = () => {
  const value = useContext(AppLoaderContext)

  if (value == null) {
    throw new Error('useAppLoaderContext cannot be used outside of AppLoaderProvider')
  }

  return value
}
