'use client'

import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { usePathname, useRouter, useSearchParams } from 'next/navigation'

import configs from '@/configs'
import useCheckAuth from '@/hooks/auth/useCheckAuth'
import useHash from '@/hooks/useHash'
import { useConfigsContext } from '@/context/ConfigsContext'
import { useBISdkContext } from '@/context/BISdkContext'
import { getLoginUrl, getLogoutUrl } from '@/lib/url'
import { useAppLoaderContext } from '@/context/AppLoaderContext'

type AuthContextType = {
  isSignedIn: boolean
  handleLogin: () => void
  handleLogout: () => void
}

const AuthContext = createContext<AuthContextType | null>(null)

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const [hasCheckedAuth, setHasCheckedAuth] = useState(false)

  const hash = useHash()
  const router = useRouter()
  const pathname = usePathname()
  const searchParams = useSearchParams()

  const { setIsAppLoading } = useAppLoaderContext()
  const { removeStore } = useConfigsContext()
  const { isLoadedBISdk } = useBISdkContext()
  const { isSignedIn, setIsSignedIn, checkAuth } = useCheckAuth()

  const handleLogin = useCallback(async () => {
    setIsAppLoading(true)
    const loginUrl = await getLoginUrl()
    router.push(loginUrl)
  }, [router, setIsAppLoading])

  const handleLogout = useCallback(async () => {
    setIsAppLoading(true)
    const logoutUrl = await getLogoutUrl()
    router.push(logoutUrl)
  }, [router, setIsAppLoading])

  useEffect(() => {
    if (searchParams.get('debug') === 'true') {
      // TODO: just debug now.
      // @ts-ignore
      window._CONFIGS_ = configs
    }
  }, [searchParams])

  useEffect(() => {
    if (hash === '#logout') {
      setIsAppLoading(true)
      setIsSignedIn(false)
      removeStore()

      const newURL = pathname + searchParams.toString()
      window.history.replaceState({}, document.title, newURL)

      setIsAppLoading(false)
    }
  }, [hash, pathname, removeStore, searchParams, setIsSignedIn, setIsAppLoading])

  useEffect(() => {
    if (isLoadedBISdk && !hasCheckedAuth) {
      setHasCheckedAuth(true)
      checkAuth()
    }
  }, [checkAuth, isLoadedBISdk, hasCheckedAuth])

  return (
    <AuthContext.Provider
      value={{
        isSignedIn,
        handleLogin,
        handleLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuthContext = () => {
  const value = useContext(AuthContext)

  if (value == null) {
    throw new Error('useAuthContext cannot be used outside of AuthProvider')
  }

  return value
}
