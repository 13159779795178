import { useHeroBannerContext } from '@/context/HeroBannerContext'
import { cn } from '@/lib/utils'

type BannerDotsProps = {
  count: number
}

function BannerDots({ count }: BannerDotsProps) {
  const { currentIndex, handleGoTo } = useHeroBannerContext()
  return (
    <div className="absolute -bottom-5 flex h-[6px] w-full items-center justify-center gap-2 text-center text-sm text-white md:hidden lg:-bottom-5 lg:flex">
      {Array.from({ length: count }).map((_, index) => (
        <span
          key={`carousel-order-dots-${index}`}
          className={cn('h-[6px] cursor-pointer transition-all duration-700 ease-in-out', {
            'w-6 rounded-lg bg-vive-brand-50': currentIndex === index,
            'aspect-square rounded-full bg-vive-primary-70 hover:bg-vive-primary-50':
              currentIndex !== index,
          })}
          onClick={() => handleGoTo(index)}
        />
      ))}
    </div>
  )
}

export default BannerDots
