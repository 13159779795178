import configs from '@/configs'
import { DEFAULT_VRAM_KB } from '@/constants/device'
import { DeviceType } from '@/types/configs'

export const getDeviceToVram = () => {
  const deviceToVram =
    typeof window === 'undefined'
      ? globalThis.appConfigFromCache?.features?.device_to_vram
      : configs.appConfig?.features?.device_to_vram
  return deviceToVram || []
}

export const getTotalVramKb = (deviceType: DeviceType) => {
  const deviceToVram = getDeviceToVram()
  const totalVramKb =
    deviceToVram?.find((device) => device.device === deviceType)?.top_vram_kb || DEFAULT_VRAM_KB

  return totalVramKb
}
