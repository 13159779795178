'use client'

import Script from 'next/script'
import { useLocale } from 'next-intl'
import {
  PropsWithChildren,
  createContext,
  startTransition,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { VSC_SDK_LOADED_EVENT } from '@/constants/vsc-sdk'
import { enableDevShareComponent } from '@/configs/utils'
import configs from '@/configs'
import { setUserLocale } from '@/server/services/locales'
import { Locale } from '@/server/constants/locales'
import { useAppLoaderContext } from '@/context/AppLoaderContext'

type VSCSdkContextType = {
  viverseShareComponent: ViverseShareComponent | null
  isMountedHeader: boolean
  setIsMountedHeader: (mounted: boolean) => void
  changeLocale: (locale: Locale) => void
}

const VSCSdkContext = createContext<VSCSdkContextType | null>(null)

const createShareComponentLinks = () => {
  const { hubs_domain } = configs?.appConfig?.features || {}
  const { viverse_landing, viverse_market, viverse_avatar } = configs?.appConfig?.links || {}
  const domain = enableDevShareComponent() ? location.origin : `https://${hubs_domain}`
  return {
    viverse: viverse_landing!,
    world: domain,
    market: viverse_market!,
    avatar: viverse_avatar!,
    create: domain,
  }
}

export const VSCSdkProvider = ({ children }: PropsWithChildren) => {
  const sdkUri = configs.appConfig?.features?.share_component_sdk_uri
  const lang = useLocale()
  const [isLoadedVSCSdk, setIsLoadedVSCSdk] = useState(false)
  const [isMountedHeader, setIsMountedHeader] = useState(false)
  const [viverseShareComponent, setViverseShareComponent] = useState<ViverseShareComponent | null>(
    null,
  )
  const { setIsAppLoading } = useAppLoaderContext()

  useEffect(() => {
    if (!isMountedHeader) {
      setIsAppLoading(true)
    } else {
      setIsAppLoading(false)
    }
  }, [isMountedHeader, setIsAppLoading])

  const handleLoad = () => {
    setIsLoadedVSCSdk(true)

    console.log('✅ VSC_SDK loaded')
    window.dispatchEvent(new CustomEvent(VSC_SDK_LOADED_EVENT))
  }

  const handleError = () => {
    throw new Error('Failed to load VSC_SDK' + sdkUri)
  }

  const changeLocale = useCallback((locale: Locale) => {
    startTransition(() => {
      setIsMountedHeader(false)
      setUserLocale(locale)
    })
  }, [])

  useEffect(() => {
    if (isLoadedVSCSdk && ViverseShareComponent) {
      setViverseShareComponent(
        new ViverseShareComponent({
          i18n: {
            lang,
          },
          origin: createShareComponentLinks(),
        }),
      )
    }
  }, [isLoadedVSCSdk, lang])

  return (
    <VSCSdkContext.Provider
      value={{ viverseShareComponent, isMountedHeader, setIsMountedHeader, changeLocale }}
    >
      {children}
      <Script src={sdkUri} onLoad={handleLoad} onError={handleError} />
    </VSCSdkContext.Provider>
  )
}

export const useVSCSdkContext = () => {
  const value = useContext(VSCSdkContext)

  if (value == null) {
    throw new Error('useVSCSdkContext cannot be used outside of VSCSdkProvider')
  }

  return value
}
