'use client'

import { useRef, useEffect, useState } from 'react'
import { useRouter } from 'next/navigation'

import { useConfigsContext } from '@/context/ConfigsContext'
import { useAuthContext } from '@/context/AuthContext'
import { useVSCSdkContext } from '@/context/VSCSdkContext'
import ViverseCommunityDialog from '@/components/ViverseCommunityDialog'
import { useShareComponentProfile } from '@/hooks/useShareComponentProfile'
import { useAppLoaderContext } from '@/context/AppLoaderContext'

function Header() {
  const headerRef = useRef<HTMLDivElement>(null)
  const [openCommunityDialog, setOpenCommunityDialog] = useState(false)

  const router = useRouter()
  const { store } = useConfigsContext()
  const { isSignedIn, handleLogin, handleLogout } = useAuthContext()
  const { setIsAppLoading } = useAppLoaderContext()
  const { viverseShareComponent, isMountedHeader, setIsMountedHeader, changeLocale } =
    useVSCSdkContext()
  const shareComponentProfile = useShareComponentProfile()

  const communityToggle = store?.communityToggle

  useEffect(() => {
    type ChangeRouteArgs = {
      origin: string
      path: string
    }

    function handleCreateWorld() {
      router.push('/user-worlds?create')
    }

    function handleChangeRoute(event: CustomEvent<ChangeRouteArgs>) {
      const origin = event.detail.origin
      const path = event.detail.path

      setIsAppLoading(true)

      if (window.location.origin === origin) {
        router.push(path)
      } else {
        window.location.href = `${origin}${path}`
      }

      setIsAppLoading(false)
    }

    // 🌎 handle CustomEvent from share-component
    document.addEventListener('createWorld', handleCreateWorld)
    document.addEventListener('onChangeRoute', handleChangeRoute as EventListener)

    return () => {
      document.removeEventListener('createWorld', handleCreateWorld)
      document.removeEventListener('onChangeRoute', handleChangeRoute as EventListener)
    }
  }, [router, setIsAppLoading])

  useEffect(() => {
    if (isMountedHeader && viverseShareComponent) {
      viverseShareComponent.updateProfile(shareComponentProfile)
    }
  }, [isMountedHeader, viverseShareComponent, isSignedIn, shareComponentProfile])

  useEffect(() => {
    if (viverseShareComponent && headerRef.current) {
      viverseShareComponent.mountHeader({
        $header: headerRef.current,
        localeConfig: {
          changeLocale,
        },
        authConfig: {
          onLogin: handleLogin,
          onLogout: handleLogout,
          profile: shareComponentProfile,
        },
        layoutConfig: {
          type: 6,
          community: {
            isChinaUser: !communityToggle,
            showIframe: () => setOpenCommunityDialog(true),
          },
        },
      })

      setIsMountedHeader(true)
    }
  }, [
    handleLogin,
    handleLogout,
    setIsMountedHeader,
    shareComponentProfile,
    viverseShareComponent,
    communityToggle,
    changeLocale,
  ])

  return (
    <>
      <header
        ref={headerRef}
        className="fixed top-0 z-50 h-[52px] w-full bg-transparent transition-all md:h-[56px] lg:h-[80px]"
      />
      <ViverseCommunityDialog open={openCommunityDialog} onOpenChange={setOpenCommunityDialog} />
    </>
  )
}

export default Header
