'use client'

import useSWR from 'swr'
import { useEffect, useRef, useState } from 'react'
import { useTranslations } from 'next-intl'
import Image from 'next/image'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { motion } from 'framer-motion'

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import ViverseInput from '@/components/ViverseInput'
import ViverseButton from '@/components/ViverseButton'
import DropdownSelect from '@/components/DropdownSelect'
import ViverseInputOTP from '@/components/ViverseInputOTP'
import { useRoomPolicy } from '@/hooks/useRoomPolicy'
import { useUploadFiles } from '@/hooks/useUploadFiles'
import { CreateAGiveawayRoomData, getRoomTags } from '@/services/cms'
import { ImageAsset, Policy } from '@/types/world'
import { cn } from '@/lib/utils'
import ViverseAutoComplete from '@/components/ViverseAutoComplete'
import ViverseSwitch from '@/components/ViverseSwitch'
import { Button } from '@/components/ui/button'
import { Skeleton } from '@/components/ui/skeleton'
import roomDefaultImage from '@/public/images/room-default.png'
import {
  MAX_TITLE_LENGTH,
  useWorldSettingsFormSchema,
  WorldSettingsFormValues,
} from '@/hooks/useWorldSettingsFormSchema'
import { useCreateWorldSettingsDefaultRoomTitle } from '@/hooks/useCreateWorldSettingsDefaultRoomTitle'
import { useResizeObserver } from 'usehooks-ts'

export type WorldSettingsDefaultValues = {
  title: string
  description: string
  policy: Policy
  passcode?: string
  genre?: string[]
  enablePasscode?: boolean
}

type WorldSettingsProps = {
  defaultValues?: WorldSettingsDefaultValues
  defaultThumbnail?: ImageAsset
  sceneSid?: string
  onComplete: (data: CreateAGiveawayRoomData) => void
  mode?: 'create' | 'update'
}

export const WORLD_SETTINGS_DEFAULT_VALUES: WorldSettingsDefaultValues = {
  title: '',
  description: '',
  policy: 'private',
  passcode: '',
  genre: [],
  enablePasscode: false,
}

const createAutoCompleteOptions = <T extends string>(options: T[]) => {
  return options.map((value) => ({ label: value, value })) || []
}

function WorldSettings({
  defaultValues = WORLD_SETTINGS_DEFAULT_VALUES,
  defaultThumbnail,
  sceneSid,
  onComplete,
  mode = 'create',
}: WorldSettingsProps) {
  const t = useTranslations()
  const ROOM_POLICY = useRoomPolicy()

  const [policy, setPolicy] = useState<keyof typeof ROOM_POLICY>(
    defaultValues.policy || WORLD_SETTINGS_DEFAULT_VALUES.policy,
  )

  const sectionRef = useRef<HTMLDivElement>(null)

  const defaultRoomTitle = useCreateWorldSettingsDefaultRoomTitle()
  const formSchema = useWorldSettingsFormSchema()

  const { width = 0 } = useResizeObserver({
    ref: sectionRef,
  })

  const form = useForm<WorldSettingsFormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      ...defaultValues,
      title: defaultValues.title || defaultRoomTitle,
    },
  })
  const formValues = form.watch()
  const selectedGenres = formValues.genre || []
  const enablePasscode = formValues.enablePasscode || false

  const { data, isLoading } = useSWR('getGenresList', () =>
    getRoomTags(false).then((res) => res?.results?.results),
  )

  const genreOptions = createAutoCompleteOptions(
    (!isLoading && data?.map((genre) => genre.name)) || [],
  )

  const selectedGenreOptions = createAutoCompleteOptions(selectedGenres)

  useEffect(() => {
    if (defaultValues.enablePasscode) {
      // set passcode to ****** when it's enabled
      form.setValue('enablePasscode', true)
      form.setValue('passcode', '******')
    }
  }, [form, defaultValues.enablePasscode])

  const onSubmit = async (values: WorldSettingsFormValues) => {
    const policyValue = ROOM_POLICY[policy].value

    const isPasscodeEnabled = values.enablePasscode && policyValue !== 'owner_only'
    const isPasscodeDirty = form.getFieldState('passcode').isDirty
    const isValidPasscode = !values.passcode.includes('*')

    const shouldIncludePasscode = isPasscodeEnabled && isPasscodeDirty && isValidPasscode
    const passcode = shouldIncludePasscode
      ? values.passcode
      : values.enablePasscode
        ? undefined
        : ''

    onComplete({
      policy: policyValue,
      title: values.title,
      description_plaintext: values.description || '',
      passcode,
      tags: values.genre?.join(',') || '',
      scene_sid: sceneSid || '',
      features: {
        spatial_sound: true,
      },
      image: uploadedFile?.id || defaultThumbnail?.id || '',
    })
  }

  const handlePolicyValueChange = (value: string) => {
    const policySet = Object.entries(ROOM_POLICY).find(([, policy]) => policy.value === value)

    if (!policySet) return

    if (policySet[0] !== 'private') {
      form.setValue('passcode', '')
    }

    setPolicy(policySet[0] as keyof typeof ROOM_POLICY)
  }

  const handleGenreValueChange = (value: string) => {
    if (!selectedGenres?.includes(value)) {
      const newSelectedGenres = [...selectedGenres, value]
      form.setValue('genre', newSelectedGenres)
      form.formState.isSubmitted && form.trigger('genre')
    }
  }

  const handleGenreDeleteClick = (value: string) => {
    const newSelectedGenres = selectedGenres.filter((genre) => genre !== value)
    form.setValue('genre', newSelectedGenres)
    form.formState.isSubmitted && form.trigger('genre')
  }

  const handlePasscodeSwitchChange = (checked: boolean) => {
    form.setValue('enablePasscode', checked)

    if (!checked) {
      form.clearErrors('passcode')
    }
  }

  const isFormSubmitDisabled = !!form.formState.errors.title

  const {
    inputRef,
    handleFileUpload,
    handleInputChange,
    isFileUploading,
    uploadedFile,
    error: uploadError,
  } = useUploadFiles()

  return (
    <div className="relative flex w-full flex-1 flex-col items-center">
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex w-full flex-col items-center gap-4 overflow-auto px-10 md:h-[calc(100dvh-128px-54px)] 1.5xl:h-[calc(100dvh-128px-62px)] 2xl:max-h-[708px]"
        >
          <div className="flex w-full flex-1 flex-col">
            <div className="flex w-full flex-col md:flex-row md:gap-5">
              <div ref={sectionRef} className="flex flex-col gap-3 md:w-1/2">
                <FormField
                  control={form.control}
                  name="title"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t('profile-page.my-worlds-card-settings-title')}</FormLabel>
                      <FormControl>
                        <ViverseInput
                          placeholder={t('profile-page.my-worlds-card-settings-title-caption')}
                          maxLength={MAX_TITLE_LENGTH}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="genre"
                  render={() => (
                    <FormItem>
                      <FormLabel className="flex flex-col">
                        <FormLabel>{t('profile-page.my-worlds-card-settings-genre')}</FormLabel>
                        <p className="mt-2 text-vive-primary-40">
                          {t('profile-page.my-worlds-card-settings-genre-tip')}
                        </p>
                      </FormLabel>
                      <FormControl>
                        <ViverseAutoComplete
                          placeholder={t('profile-page.my-worlds-card-settings-genre-placeholder')}
                          onValueChange={handleGenreValueChange}
                          onDeleteClick={handleGenreDeleteClick}
                          options={genreOptions}
                          selected={selectedGenreOptions}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="policy"
                  render={({ field }) => (
                    <FormItem className="w-full">
                      <FormLabel>{t('profile-page.my-worlds-card-settings-access')}</FormLabel>
                      <FormControl>
                        <DropdownSelect onValueChange={handlePolicyValueChange} {...field}>
                          <DropdownSelect.Trigger>
                            <DropdownSelect.Value
                              asChild
                              title={ROOM_POLICY[policy].title}
                              description={ROOM_POLICY[policy].description}
                            />
                          </DropdownSelect.Trigger>
                          <DropdownSelect.Content style={{ width: width + 5 }}>
                            {Object.entries(ROOM_POLICY).map(
                              ([key, { value, title, description }]) => (
                                <DropdownSelect.Item
                                  key={`dropdown-select-item-${value}`}
                                  value={value}
                                  title={title}
                                  description={description}
                                  selected={key === policy}
                                  className="w-full"
                                />
                              ),
                            )}
                          </DropdownSelect.Content>
                        </DropdownSelect>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="passcode"
                  render={({ field }) => (
                    <FormItem className={cn({ hidden: policy === 'private' }, 'w-full flex-1')}>
                      <FormLabel className="flex flex-col">
                        <FormLabel>{t('profile-page.my-worlds-card-settings-passcode')}</FormLabel>

                        <div className="flex gap-6">
                          <p className="mt-2 text-vive-primary-40">
                            {t('profile-page.my-worlds-card-settings-passcode-tip')}
                          </p>

                          <FormField
                            control={form.control}
                            name="enablePasscode"
                            render={({ field }) => (
                              <FormItem
                                className={cn({ hidden: policy === 'private' }, 'w-full flex-1')}
                              >
                                <FormLabel className="flex flex-col"></FormLabel>
                                <FormControl>
                                  <ViverseSwitch
                                    checked={field.value}
                                    onCheckedChange={handlePasscodeSwitchChange}
                                  />
                                </FormControl>
                              </FormItem>
                            )}
                          />
                        </div>
                      </FormLabel>
                      <FormControl>
                        <div
                          className={cn({
                            hidden: !enablePasscode,
                          })}
                        >
                          <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: enablePasscode ? 1 : 0 }}
                            transition={{
                              ease: 'easeOut',
                            }}
                          >
                            <div className="flex items-center gap-4">
                              <ViverseInputOTP
                                maxLength={6}
                                {...field}
                                hasError={!!form.formState.errors.passcode}
                              />
                            </div>
                            <Button
                              className="p-0"
                              onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                form.setValue('passcode', '')
                              }}
                              variant="link"
                            >
                              {t('profile-page.my-worlds-card-settings-passcode-reset')}
                            </Button>
                          </motion.div>
                        </div>
                      </FormControl>
                    </FormItem>
                  )}
                />
              </div>

              <div
                id="upload-thumbnail-panel"
                className="mt-3 min-h-[300px] text-center md:mt-0 md:w-1/2"
              >
                <div className="mb-2">
                  <div className="text-left">
                    <FormLabel>
                      {t('profile-page.my-worlds-card-settings-thumbnail-select')}
                    </FormLabel>
                  </div>
                  <div className="caption text-left text-vive-primary-40">
                    {t('profile-page.my-worlds-card-settings-thumbnail-maximum')}
                  </div>
                </div>

                <div className="relative min-h-[236px] w-full overflow-hidden rounded-lg">
                  {isFileUploading ? (
                    <Skeleton className="min-h-[236px] w-full" />
                  ) : (
                    <Image
                      src={uploadedFile?.url || defaultThumbnail?.url || roomDefaultImage}
                      alt="uploaded image of room"
                      style={{ objectFit: 'cover' }}
                      fill
                    />
                  )}
                </div>

                {uploadError && <div className="caption">{uploadError.message}</div>}
                <ViverseButton
                  className="mt-4"
                  onClick={handleFileUpload}
                  variant="outline"
                  size="xLarge"
                >
                  {t('profile-page.my-worlds-card-settings-thumbnail')}
                </ViverseButton>
              </div>
            </div>

            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem className="mt-3 w-full flex-1 pb-8">
                  <FormLabel>{t('profile-page.my-worlds-card-settings-description')}</FormLabel>
                  <FormControl>
                    <ViverseInput
                      placeholder={t('profile-page.my-worlds-card-settings-description-caption')}
                      maxLength={200}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <div className="sticky bottom-6 flex w-full items-center justify-center bg-vive-popup">
            <ViverseButton
              type="submit"
              disabled={isFormSubmitDisabled}
              variant="colorful"
              size="xLarge"
            >
              {mode === 'create'
                ? t('profile-page.my-worlds-card-settings-create-button')
                : t('profile-page.my-worlds-card-settings-save-button')}
            </ViverseButton>
          </div>
        </form>
        <input
          type="file"
          ref={inputRef}
          style={{ display: 'none' }}
          onChange={handleInputChange}
        />
      </Form>
    </div>
  )
}

export default WorldSettings
