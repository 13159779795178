import Image from 'next/image'
import { motion } from 'framer-motion'
import { forwardRef } from 'react'

import BannerTitle from '@/app/BannerTitle'
import { ActivityShowcaseList } from '@/services/cms'
import { Skeleton } from '@/components/ui/skeleton'
import { cn } from '@/lib/utils'
import { useVideoThumbnail } from '@/hooks/useVideoThumbnail'
import { useHeroBannerContext } from '@/context/HeroBannerContext'

type BannerGalleryItemProps = {
  list: ActivityShowcaseList
  isActive: boolean
  onClick: () => void
}

const BannerGalleryItem = forwardRef<HTMLDivElement, BannerGalleryItemProps>(
  function BannerGalleryItem({ list, isActive, onClick }, ref) {
    const { thumbnail, isLoadingThumbnail } = useVideoThumbnail(
      list.video?.mp4,
      Boolean(list.video?.image),
    )
    const { isPageVisible, currentProgress } = useHeroBannerContext()

    const renderImage = () => {
      if (list.video) {
        if (list.video.image) {
          return <Image src={list.video.image} alt={list.title} fill className="object-cover" />
        }
        if (isLoadingThumbnail) {
          return <Skeleton className="size-full bg-vive-primary-70" />
        }
        if (thumbnail) {
          return <Image src={thumbnail} alt={list.title} fill className="object-cover" />
        }
        return null
      }

      if (list.background) {
        return <Image src={list.background!} alt={list.title} fill className="object-cover" />
      }

      return null
    }

    return (
      <div
        className={cn(
          'relative flex max-h-[72px] min-h-[72px] min-w-[169px] max-w-[169px] cursor-pointer items-center gap-4 overflow-hidden rounded-2xl px-4 py-3 transition-all hover:bg-vive-surface-normal lg:max-h-[132px] lg:min-h-[132px] lg:min-w-[248px] lg:max-w-[248px]',
          {
            'bg-vive-surface-normal': isActive,
          },
        )}
        onClick={onClick}
        ref={ref}
      >
        {isActive && isPageVisible && (
          <motion.div
            className="absolute inset-0 z-10 bg-[#00A0CB66]"
            initial={{ width: 0 }}
            animate={{ width: `${currentProgress}%` }} // Animate based on the progress state
            transition={{
              type: 'spring',
              damping: 25,
            }}
          />
        )}

        <div className="relative grid min-h-[56px] min-w-[49px] overflow-hidden rounded-2xl lg:max-h-[100px] lg:min-h-[100px] lg:min-w-[80px] lg:max-w-[80px]">
          {renderImage()}
        </div>

        <BannerTitle title={list.title} title_html={list.title_html} variant="gallery" />
      </div>
    )
  },
)

export default BannerGalleryItem
