import { useTranslations } from 'next-intl'

import BannerDescription from '@/app/BannerDescription'
import BannerTitle from '@/app/BannerTitle'
import ViverseButton from '@/components/ViverseButton'
import { useUAContext } from '@/context/UAContext'
import { cn } from '@/lib/utils'
import { ActivityShowcaseList } from '@/services/cms'

type BannerImageInfoProps = {
  item: ActivityShowcaseList
  onClick: (link: string) => void
}

function BannerImageInfo({ item, onClick }: BannerImageInfoProps) {
  const t = useTranslations()
  const { deviceType } = useUAContext()
  return (
    <div
      className={cn(
        'absolute bottom-0 flex h-fit w-full flex-col justify-end gap-9 rounded-none border-0 bg-transparent md:top-1/2 md:w-auto md:-translate-y-1/2 md:gap-8 lg:flex-row lg:justify-center lg:gap-0',
      )}
    >
      <div className="flex w-full flex-col gap-4 bg-hero-card-description-mobile p-4 md:bg-hero-card-description">
        <div>
          <BannerTitle title={item.title} title_html={item.title_html} variant="image" />
          <BannerDescription
            description={item.description}
            description_html={item.description_html}
            variant="image"
          />
        </div>
        {item.action_link && (
          <div className="flex flex-col items-start justify-center gap-[10px]">
            <ViverseButton
              size="filter"
              variant="default"
              className="h-[34px] w-full md:w-fit md:!min-w-fit 1.5xl:h-[48px] 1.5xl:px-[20px] 1.5xl:py-3 1.5xl:text-lg"
              onClick={() => onClick(item.action_link)}
            >
              {item.action_title}
            </ViverseButton>
            {item.enable_no_signup_hint && (
              <div className="hidden text-xs text-white md:block">
                {t('homepage.banner.cta_tips')}
              </div>
            )}
          </div>
        )}
        {deviceType === 'desktop' && (
          <p>
            {t('homepage.banner.device_tips')}:{' '}
            {item.preferred_devices?.map((device) => t(`device-filter.${device}`)).join(', ')}
          </p>
        )}
      </div>
    </div>
  )
}

export default BannerImageInfo
