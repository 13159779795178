'use client'

import { PropsWithChildren } from 'react'
import { useBIPageView } from '@/context/BISdkContext'

function LandingPageWrapper({ children }: PropsWithChildren) {
  useBIPageView()

  return <main>{children}</main>
}

export default LandingPageWrapper
